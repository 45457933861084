import { Link } from 'react-router-dom';
type Event = {
  id: string;
  name: string;
  details: string;
  items: {
    name: string;
  }[];
  guests: {
    email: string;
  }[];
};
export function CreateEventSuccessPage(props: Event) {
  return (
    <div className="flex flex-col py-10 max-w-lg mx-auto mx-4">
      <div className="text-center">
        <h1 className="text-4xl font-bold mb-4">Your event has been created!</h1>
        <h2 className="text-2xl font-semibold mb-10">## emails have been sent.</h2>
      </div>
      {/* <Link to={'/view-event/'}>
        <button className="h-10 px-6 text-white bg-black rounded-full">Go to My Dashboard</button>
      </Link> */}
      <div className="flex flex-col items-center">
        <Link to={`/view-event/${props.id}`}>
          <button className="h-10 px-6 mb-4 text-white bg-black rounded-full font-semibold">
            View Event
          </button>
        </Link>
      </div>
      <div className="flex flex-col items-center">
        <Link to={'/dashboard'}>
          <button className="h-10 px-6 text-white bg-black rounded-full font-semibold">
            Go to My Dashboard
          </button>
        </Link>
      </div>
    </div>
  );
}
