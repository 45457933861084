import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import App from './App';
import { ErrorPage } from './pages/ErrorPage';
import { HomePage } from './pages/HomePage';
import { CreateEventPage } from './pages/CreateEventPage';
import { CreateEventSuccessPage } from './pages/CreateEventSuccessPage';
import { AccountDashboardPage } from './pages/AccountDashboardPage';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    // Specifying the error page on the root route means that it will show if any routes have errors
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: 'dashboard',
        // eslint-disable-next-line react/jsx-no-undef
        element: <AccountDashboardPage />,
      },
      {
        path: 'create-event',
        element: <CreateEventPage />,
      },
      {
        path: 'create-event-success',
        element: <CreateEventSuccessPage id={''} name={''} details={''} items={[]} guests={[]} />,
      },
    ],
  },
]);

export function Routes() {
  return <RouterProvider router={router} />;
}
