import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

type Event = {
  id: string;
  name: string;
  details: string;
  items: {
    name: string;
  }[];
  guests: {
    email: string;
  }[];
};

export function CreateEventPage() {
  const { register, control, handleSubmit } = useForm<Event>({
    defaultValues: {
      name: '',
      details: '',
      items: [
        {
          name: '',
        },
      ],
      guests: [
        {
          email: '',
        },
      ],
    },
  });

  const {
    fields: fieldItems,
    append: appendItems,
    remove: removeItems,
  } = useFieldArray({
    name: 'items',
    control,
  });

  const {
    fields: fieldGuests,
    append: appendGuests,
    remove: removeGuests,
  } = useFieldArray({
    name: 'guests',
    control,
  });

  const navigate = useNavigate();
  const eventId = 0;

  function onSubmit(event: Event) {
    event = { ...event, id: eventId.toString() };
    console.log('Submitted event:', event);
    navigate(`/create-event-success`, {
      /* pass props here to CreateEventSuccessPage component */
    });
  }

  const fieldStyle = 'flex flex-col w-* mb-4';

  return (
    <div className="flex flex-col py-10 max-w-lg mx-auto mx-4">
      <div className="text-center">
        <h2 className="text-4xl font-bold mb-12">Create an Event</h2>
      </div>
      <h2 className="text-2xl font-bold mb-6">Event Info</h2>
      <form noValidate className="mb-6" onSubmit={handleSubmit(onSubmit)}>
        <div className={fieldStyle}>
          <label htmlFor="name"></label>
          <input
            className="rounded-lg border-1"
            type="text"
            id="name"
            placeholder="Name of Event"
            {...register('name')}
          ></input>
        </div>
        <div className={fieldStyle}>
          <label htmlFor="details"></label>
          <input
            className="rounded-lg"
            type="text"
            id="details"
            placeholder="Event details"
            {...register('details')}
          ></input>
        </div>
        <div>
          <h2 className="text-2xl font-bold mb-6 mt-12">Items Needed</h2>
        </div>
        {fieldItems.map((fieldItem, indexItem) => {
          return (
            <div key={fieldItem.id} className={fieldStyle}>
              <div className="flex flex-row items-center">
                <input
                  className={
                    indexItem !== fieldItems.length - 1
                      ? 'w-full border-0 border-b-2 border-gray-400 focus:ring-0 focus:border-blue'
                      : 'w-full rounded-lg'
                  }
                  type="text"
                  placeholder="Item"
                  readOnly={indexItem !== fieldItems.length - 1 ? true : false}
                  {...register(`items.${indexItem}.name` as const)}
                ></input>
                <button className="ml-4" onClick={() => removeItems(0)}>
                  ✖️
                </button>
              </div>
            </div>
          );
        })}
        <div className="flex flex-col items-center">
          <button
            type="button"
            className="h-10 px-6 text-white bg-black font-semibold rounded-full"
            onClick={() => appendItems({ name: '' })}
          >
            Add Item
          </button>
        </div>
        <div>
          <h2 className="text-2xl font-bold mb-6 mt-12">Guest List</h2>
        </div>
        {fieldGuests.map((fieldGuest, indexGuest) => {
          return (
            <div key={fieldGuest.id} className={fieldStyle}>
              <div className="flex flex-row items-center">
                <input
                  className={
                    indexGuest !== fieldGuests.length - 1
                      ? 'w-full border-0 border-b-2 border-gray-400 focus:ring-0 focus:border-blue'
                      : 'w-full rounded-lg'
                  }
                  type="email"
                  placeholder="Guest email"
                  readOnly={indexGuest !== fieldGuests.length - 1 ? true : false}
                  {...register(`guests.${indexGuest}.email` as const)}
                ></input>
                <button type="button" className="ml-4" onClick={() => removeGuests(0)}>
                  ✖️
                </button>
              </div>
            </div>
          );
        })}
        <div className="flex flex-col items-center">
          <button
            type="button"
            className="h-10 px-6 text-white bg-black font-semibold rounded-full"
            onClick={() => appendGuests({ email: '' })}
          >
            Add Guest
          </button>
        </div>
        <div className="flex flex-col items-center">
          <button
            type="submit"
            className="mt-10 h-10 px-6 font-semibold bg-black rounded-full w-64 text-white"
          >
            Create & Send
          </button>
        </div>
      </form>
    </div>
  );
}
