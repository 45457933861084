import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

type Contact = {
  name: string;
  email: string;
  reason: string;
  notes: string;
};

export function AccountDashboardPage() {
  const { register } = useForm<Contact>({});
  const fieldStyle = 'flex flex-col w-* mb-4';

  return (
    <div className="flex flex-col py-10 max-w-lg mx-auto">
      <div className="text-center">
        <h2 className="text-4xl font-bold mb-12">My Dashboard</h2>
      </div>
      <h2 className="text-2xl font-bold mb-6">My Profile</h2>
      <form noValidate className="mb-6">
        <div className={fieldStyle}>
          <label htmlFor="name"></label>
          <input
            className="rounded-lg"
            type="text"
            id="name"
            placeholder="Your name here"
            {...register('name')}
          ></input>
        </div>
        <div className={fieldStyle}>
          <label htmlFor="email"></label>
          <input
            className="rounded-lg"
            type="email"
            id="email"
            placeholder="Your email here"
            {...register('email')}
          ></input>
        </div>
      </form>
      <h2 className="text-2xl font-bold mb-6">My Events</h2>
      <div className="flex flex-col items-center">
        <Link to={'/create-event'}>
          <button className="h-10 px-6 text-white bg-black rounded-full font-semibold">
            Create Event
          </button>
        </Link>
      </div>
      <ul className="list-none m-0 p-0">
        {/* {getEvents().map((event) => (
          // Link paths are relative to the component’s path. Given that the component path is /products, we set the link path to the product ID to build /products/:id
          <li key={event.id} className="p-1 text-base text-slate-800 hover:underline">
            <Link to={`${event.id}`}>{event.name}</Link>
          </li>
        ))} */}
      </ul>
    </div>
  );
}
