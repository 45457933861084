import { Link, NavLink, useSearchParams } from 'react-router-dom';

export function Header() {
  return (
    <header>
      <div className="text-center font-bold p-5">
        {/* img and header links to HomePage index route */}
        <Link to="">
          <h1 className="text-2xl rotate-45">➗</h1>
        </Link>
        <Link to="">
          <h1 className="text-2xl">DIVS</h1>
        </Link>
      </div>
      <div className="text-center font-normal">
        <nav>
          <NavLink
            to="dashboard"
            className={({ isActive }) =>
              `no-underline p-1 pb-0.5 border-solid border-b-2 ${
                isActive ? 'font-bold border-black' : 'border-transparent'
              }`
            }
          >
            Dashboard
          </NavLink>
          <NavLink
            to="create-event"
            className={({ isActive }) =>
              `no-underline p-1 pb-0.5 border-solid border-b-2 ${
                isActive ? 'font-bold border-black' : 'border-transparent'
              }`
            }
          >
            Create Event
          </NavLink>
        </nav>
      </div>
    </header>
  );
}
